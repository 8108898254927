import { useState } from "react";
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



//Type of Method in JS
const LoginSignupPage = ({ setIsLoggedIn }) => {

    const navigate = useNavigate();

    //Basic Initalization done
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');


    //async can be used when handlind
    //Aysnchronos Operations like data fetching
    //Or User operations
    const handleSubmit =  (e) => {

        e.preventDefault();

        console.log("isLogin ",isLogin)

        //await and asyc removed since it was asychronus
        const endpoint = isLogin ? "http://localhost:4102/auth/signin" : "http://localhost:4102/auth/signup"
        const payload = isLogin ? { "username":username, "password":password } : { "username":username, "password":password,"email":email }
        console.log("Outgoing Payload ",payload)
        const response = axios.post(endpoint, payload);

        console.log("response :: ",response)
        setIsLoggedIn(true);
        navigate('/add-venue');

        console.log("response :: ")

    }

    return (


        <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    {isLogin ? 'Sign in to your account' : 'Create a new account'}
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        {!isLogin && (
                            <div>
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                                <div className="mt-1">
                                    <input id="name" name="username" type="text" required value={username} onChange={(e) => setUsername(e.target.value)}
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                        )}
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Email address</label>
                            <div className="mt-1">
                                <input id="username" name="username" type="email" autoComplete="name" required value={username} onChange={(e) => setUsername(e.target.value)}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                            <div className="mt-1">
                                <input id="password" name="password" type="password" autoComplete="current-password" required value={password} onChange={(e) => setPassword(e.target.value)}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <button type="submit"
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                {isLogin ? 'Sign in' : 'Sign up'}
                            </button>
                        </div>
                    </form>

                    <div className="mt-6">
                        <button
                            onClick={() => setIsLogin(!isLogin)} className="w-full text-center text-sm text-indigo-600 hover:text-indigo-500">
                            {isLogin ? 'Create a new account' : 'Already have an account? Sign in'}
                        </button>
                    </div>
                </div>
            </div>
        </div>


    );
};


//It can used to import in Any other File
export default LoginSignupPage;








