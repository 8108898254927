import React from 'react';

function AboutSection() {

  const destinations = {
    "Destination Cities": [
      "Asia",
      "Bali Hotels",
      "Bandung Hotels",
      "Bangkok Hotels",
      "Boracay Island Hotels",
      "Busan Hotels",
      "Cebu Hotels",
      "Chiang Mai Hotels",
      "Da Nang Hotels",
      "Fukuoka Hotels",
      "Hanoi Hotels",
      "Hai Vai Hotels",
      "Ho Chi Minh City Hotels",
      "Hoi An Hotels",
      "Hong Kong Hotels",
      "Hua Hin Hotels",
      "Hualien Hotels",
      "Ipoh Hotels",
      "Jakarta Hotels",
      "Jeju Island Hotels",
      "Johor Bahru Hotels",
      "Kaohsiung Hotels",
      "Kota Kinabalu Hotels",
      "Krabi Hotels",
      "Kuala Lumpur Hotels",
      "Kuantan Hotels",
      "Kyoto Hotels"
    ],
    "Countries & Territories": [
      "Europe",
      "Africa",
      "Morocco Hotels",
      "South Africa Hotels",
      "Americas",
      "Argentina Hotels",
      "Brazil Hotels",
      "Canada Hotels",
      "Mexico Hotels",
      "United States Hotels",
      "Venezuela Hotels",
      "Asia",
      "Cambodia Hotels",
      "China Hotels",
      "India Hotels",
      "Indonesia Hotels",
      "Japan Hotels",
      "Laos Hotels",
      "Malaysia Hotels",
      "Myanmar Hotels",
      "Nepal Hotels",
      "Philippines Hotels",
      "South Korea Hotels",
      "Sri Lanka Hotels",
      "Taiwan Hotels",
      "Thailand Hotels",
      "Vietnam Hotels"
    ],
    "India": [
      "Austria Hotels",
      "Czech Republic Hotels",
      "Denmark Hotels",
      "Finland Hotels",
      "France Hotels",
      "Germany Hotels",
      "Greece Hotels",
      "Hungary Hotels",
      "Ireland Hotels",
      "Italy Hotels",
      "Netherlands Hotels",
      "Russia Hotels",
      "Spain Hotels",
      "Sweden Hotels",
      "Switzerland Hotels",
      "United Kingdom Hotels"
    ],
    "Destination Guides": [
      "Destination Guides Home",
      "Asia",
      "Bali Guide",
      "Bangkok Guide",
      "Hong Kong Guide",
      "Jakarta Guide",
      "Jeju Island Guide",
      "Kuala Lumpur Guide",
      "Kyoto Guide",
      "Kyushu Guide",
      "Maldives Guide",
      "Manila Guide",
      "Nara Guide",
      "Osaka Guide",
      "Phuket Guide",
      "Sapporo Guide",
      "Seoul Guide",
      "Singapore Guide",
      "Taichung Guide",
      "Taipei Guide",
      "Tokyo Guide",
      "Korea Tourism organization",
      "Europe",
      "Paris Guide",
      "Middle East",
      "Dubai Guide",
      "Jeddah Guide",
      "Saudi Arabia Guide",
      "Oceania",
      "Brisbane Guide",
      "Melbourne Guide",
      "Sydney Guide"
    ],
    "RiteVenue Homes": [
      "Asia Apartments",
      "Bangkok Apartments",
      "Kuala Lumpur Apartments",
      "Manila Apartments",
      "Osaka Apartments",
      "Pattaya Apartments",
      "Tokyo Apartments",
      "Asia Bungalows",
      "Bali Bungalows",
      "Koh Kood Bungalows",
      "Koh Lanta Bungalows",
      "Koh Phangan Bungalows",
      "Koh Samet Bungalows",
      "Phu Quoc Island Bungalows",
      "Asia Villas",
      "Bali Villas",
      "Phuket Villas",
      "Pattaya Villas",
      "Hua Hin / Cha-am Villas",
      "Seoul Villas",
      "Port Dickson Villas",
      "Asia Vacation Rentals",
      "Tokyo Vacation Rentals",
      "Bangkok Vacation Rentals"
    ]
  };


  return (

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          {Object.entries(destinations).map(([categories,values])=>(
            <div key={categories} className="space-y-4">
              <h2 className="text-xl font-bold">{categories}</h2>
              <ul className="space-y-2">
                 {values.map((item,index)=>(
                   <li key={index} className="text-sm">{item}</li>
                 ))}
              </ul>
            </div>
          ))}
        </div>
      </div>


    // <div className="container mx-auto px-4 py-8">
    //   <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
    //     {Object.entries(destinations).map(([category, items]) => (
    //       <div key={category} className="space-y-4">
    //         <h2 className="text-xl font-bold">{category}</h2>
    //         <ul className="space-y-2">
    //           {items.map((item, index) => (
    //             <li key={index} className="text-sm">{item}</li>
    //           ))}
    //         </ul>
    //       </div>
    //     ))}
    //   </div>
    // </div>

    

  );
}

export default AboutSection;