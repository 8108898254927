
import VenueList from './components/VenueList.js';
import Header from './components/Header.js';
import HeroSection from './components/HeroSection.js';
import AboutSection from './components/AboutSection.js';
import Footer from './components/Footer.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddVenueForm from './components/AddVenueForm.js';
import React, { useState } from "react";
import LoginSignupPage from './components/LoginSignupPage.js';
import AddVenue from './components/AddVenue.js';
import './index.css'


//So App.js acts as root/main component in React.
//On top of this all the components will be holding
//the function App either return an html template or empty that doesn't matter its depends on req.

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  console.log("Is UserLogged In ",isLoggedIn);

  return (

    <Router>
      <Header />
      <Routes>
        
        {isLoggedIn ? (
          <Route path='/add-venue' element={<> <AddVenueForm isLoggedIn={isLoggedIn} />  </>} />
        ) : (
          <Route path='/login' element={<LoginSignupPage setIsLoggedIn={setIsLoggedIn} />} />
        )}

        <Route path="/" element={<>  <HeroSection /> <VenueList /> <AboutSection /> <Footer />  </>} />
        <Route path='/login' element={<LoginSignupPage setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/add-venue' element={<> <HeroSection /> <AddVenueForm isLoggedIn={isLoggedIn} />  </>} />
      </Routes>
    </Router>

  ); 

}

export default App;