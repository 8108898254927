import React from 'react';
import styled from 'styled-components';

const VenueCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const VenueImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const VenueContent = styled.div`
  padding: 15px;
  background-color: #f5f5f5;
`;

const VenueName = styled.h3`
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const VenueLocation = styled.p`
  color: #888;
  margin-bottom: 5px;
`;

const VenueAmenities = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const VenuePrice = styled.p`
  font-weight: bold;
  margin-top: 10px;
`;

const BookButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
`;

const VenueCard = ({ venue }) => {
  return (
    <VenueCardContainer>
      <VenueImage src={venue.EntryImageUrl} alt={venue.name} />
      <VenueContent>
        <VenueName>{venue.VenueId}</VenueName>
        <VenueLocation>{venue.Location}</VenueLocation>
        
        {/* <VenueAmenities>
          {venue.amenities.map((amenity) => (
            <li key={amenity}>{amenity}</li>
          ))}
        </VenueAmenities> */}

        <VenuePrice>Starting from Offer Amount of {venue.Price}!</VenuePrice>
        <BookButton>Book Now</BookButton>
      </VenueContent>
    </VenueCardContainer>
  );
};

export default VenueCard;