import React, { useState } from 'react';
import { useForm } from 'react-hook-form';


// const FormContainer = styled.form`
//   max-width: 600px;
//   margin: 0 auto;
//   padding: 20px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
// `;

// const Label = styled.label`
//   display: block;
//   margin-bottom: 10px;
//   font-weight: bold;
// `;

// const Input = styled.input`
//   border: 1px solid #ccc;
//   padding: 10px;
//   border-radius: 5px;
//   width: 100%;
//   margin-bottom: 15px;
// `;

// const TextArea = styled.textarea`
//   border: 1px solid #ccc;
//   padding: 10px;
//   border-radius: 5px;
//   width: 100%;
//   height: 100px;
//   margin-bottom: 15px;
// `;

// const Button = styled.button`
//   background-color: #4CAF50;
//   color: white;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// `;

const AddVenueForm = () => {


    const { register, handleSubmit, formState: { errors } } = useForm();
  const [images, setImages] = useState([]);
  const [addressType, setAddressType] = useState('manual');

  const onSubmit = (data) => {
    console.log({ ...data, ImagesDir: images });
    // Here you would typically send the data to your backend
  };

  const handleImageUpload = (e) => {
    const fileNames = Array.from(e.target.files).map(file => file.name);
    setImages(fileNames);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white flex items-center justify-center p-4">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 w-full max-w-2xl bg-white p-8 rounded-2xl shadow-lg border border-gray-200">
        <h1 className="text-4xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-indigo-600 mb-8">Add Venue Details</h1>

        <div className="space-y-4">
          <div>
            <label htmlFor="VenueName" className="block text-sm font-medium text-gray-700 mb-1">Venue Name</label>
            <input
              id="VenueName"
              type="text"
              {...register("VenueName", { required: "Venue name is required" })}
              className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
              placeholder="Enter venue name"
            />
            {errors.VenueName && <p className="text-red-500 text-xs mt-1">{errors.VenueName.message}</p>}
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">Address</label>
            <div className="flex space-x-4 mb-2">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="manual"
                  checked={addressType === 'manual'}
                  onChange={() => setAddressType('manual')}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2 text-gray-700">Enter Manually</span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  value="map"
                  checked={addressType === 'map'}
                  onChange={() => setAddressType('map')}
                  className="form-radio text-blue-600"
                />
                <span className="ml-2 text-gray-700">Choose from Map</span>
              </label>
            </div>

            {addressType === 'manual' ? (
              <>
                <input
                  {...register("Address.Address1", { required: "Address line 1 is required" })}
                  placeholder="Address Line 1"
                  className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                />
                <input
                  {...register("Address.Address2")}
                  placeholder="Address Line 2 (Optional)"
                  className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                />
              </>
            ) : (
              <div className="p-4 bg-blue-50 rounded-lg border border-blue-200 text-blue-700">
                Map integration would be implemented here. This is a placeholder for the map selection feature.
              </div>
            )}
          </div>

          <div>
            <label htmlFor="TypeOfEvent" className="block text-sm font-medium text-gray-700 mb-1">Type of Event</label>
            <select
              id="TypeOfEvent"
              {...register("TypeOfEvent", { required: "Event type is required" })}
              className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            >
              <option value="">Select event type</option>
              <option value="Wedding">Wedding</option>
              <option value="Corporate">Corporate</option>
              <option value="Birthday">Birthday</option>
              <option value="Other">Other</option>
            </select>
            {errors.TypeOfEvent && <p className="text-red-500 text-xs mt-1">{errors.TypeOfEvent.message}</p>}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="SeatingCapacity" className="block text-sm font-medium text-gray-700 mb-1">Seating Capacity</label>
              <input
                id="SeatingCapacity"
                type="number"
                {...register("SeatingCapacity", { required: "Seating capacity is required" })}
                className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                placeholder="Enter capacity"
              />
              {errors.SeatingCapacity && <p className="text-red-500 text-xs mt-1">{errors.SeatingCapacity.message}</p>}
            </div>
            <div>
              <label htmlFor="FloatingCapacity" className="block text-sm font-medium text-gray-700 mb-1">Floating Capacity</label>
              <input
                id="FloatingCapacity"
                type="number"
                {...register("FloatingCapacity", { required: "Floating capacity is required" })}
                className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                placeholder="Enter capacity"
              />
              {errors.FloatingCapacity && <p className="text-red-500 text-xs mt-1">{errors.FloatingCapacity.message}</p>}
            </div>
          </div>

          <div>
            <label htmlFor="SpaceType" className="block text-sm font-medium text-gray-700 mb-1">Space Type</label>
            <select
              id="SpaceType"
              {...register("SpaceType", { required: "Space type is required" })}
              className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            >
              <option value="">Select space type</option>
              <option value="Indoor">Indoor</option>
              <option value="Outdoor">Outdoor</option>
              <option value="Both">Both</option>
            </select>
            {errors.SpaceType && <p className="text-red-500 text-xs mt-1">{errors.SpaceType.message}</p>}
          </div>

          <div>
            <label htmlFor="About" className="block text-sm font-medium text-gray-700 mb-1">About</label>
            <textarea
              id="About"
              {...register("About")}
              className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
              rows="3"
              placeholder="Describe your venue"
            ></textarea>
          </div>

          <div>
            <label htmlFor="FoodType" className="block text-sm font-medium text-gray-700 mb-1">Food Type</label>
            <select
              id="FoodType"
              {...register("FoodType", { required: "Food type is required" })}
              className="w-full bg-gray-50 border border-gray-300 rounded-lg py-2 px-4 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            >
              <option value="">Select food type</option>
              <option value="Veg">Vegetarian</option>
              <option value="Nonveg">Non-vegetarian</option>
              <option value="Both">Both</option>
            </select>
            {errors.FoodType && <p className="text-red-500 text-xs mt-1">{errors.FoodType.message}</p>}
          </div>

          <div>
            <label htmlFor="images" className="block text-sm font-medium text-gray-700 mb-1">Upload Images</label>
            <input
              id="images"
              type="file"
              multiple
              onChange={handleImageUpload}
              className="w-full text-sm text-gray-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-700
                hover:file:bg-blue-100"
            />
            {images.length > 0 && (
              <p className="text-sm text-gray-600 mt-2">{images.length} image(s) selected</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Amenities</label>
            <div className="grid grid-cols-2 gap-2">
              {['Wi-Fi', 'Catering', 'AV Equipment', 'Parking'].map((amenity) => (
                <label key={amenity} className="inline-flex items-center bg-gray-100 rounded-lg p-3 transition-colors duration-200 hover:bg-gray-200">
                  <input type="checkbox" {...register("Amenities")} value={amenity} className="form-checkbox text-blue-600 rounded" />
                  <span className="ml-2 text-gray-700">{amenity}</span>
                </label>
              ))}
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Submit Venue Details
        </button>
      </form>
    </div>
  );



    // const [formData, setFormData] = useState({
    //     VenueName: '',
    //     OwnerId: '',
    //     Location: '',
    //     PinCode: '',
    //     About: ''
    // });


    // const handleChange=(f)=>{

    //     setFormData({...formData,[f.target.name]: f.target.value })

    // };

    // const handleSubmit = async (e) => {

    //     e.preventDefault();
    //     console.log("Inside the Add Venue Submit" ,e);

    //     try {
    //         const response = await fetch('http://localhost:4100/venue/save', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(formData)
    //         });

    //         if (response.ok) {
    //             console.log("VenueData Saved Successfully");
    //             alert('Venue added successfully!');
    //         } else {
    //             console.log("Error While Adding Venue ", response.statusText);
    //         }
    //     } catch (error) {
    //         console.error('Error adding venue:', error);
    //     }
    // }

    // return (

    //     <FormContainer onSubmit={handleSubmit}>
    //         <Label htmlFor="venuename">VenueName:</Label>
    //         <Input type="text" id="venuename" onChange={handleChange} name="VenueName" />

    //         <Label htmlFor="ownerid">OwnerId:</Label>
    //         <Input type="Email" id="ownerid" onChange={handleChange} name="OwnerId" />

    //         <Label htmlFor="location">Location:</Label>
    //         <Input type="text" id="location" onChange={handleChange} name="Location" />

    //         <Label htmlFor="pincode">PinCode:</Label>
    //         <Input type="text" id="pincode"  onChange={handleChange} name="PinCode" />

    //         <Label htmlFor="about">About:</Label>
    //         <TextArea id="about" onChange={handleChange} name="About" />

    //         <Button type="submit">Add Venue</Button>
    //     </FormContainer>
    // );

};

export default AddVenueForm;