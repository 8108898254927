import React , {useEffect,useState} from "react";
//import venuesData from './venues.json'
import { Link } from 'react-router-dom';
import VenueCard from "./VenueCard";
import styled from 'styled-components';
import axios from "axios";

function VenueList(){

const VenueListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

    
    // console.log("Venues ",venuesData)

    //declared empty list of venues
    const [venues,setVenues]= useState([])
    // let venueList=[]
    //mounting empty array in the end allow us to load it only once
    //If not in each render it will load
    useEffect(()=>{

       //In useEffect we are going to make a Fetch Venues Backend Call
       //Will make it once to improve the performance
       //Will use useMemo hook to call it only once
       //Type is Promise , replacement of Callback
       //Promises support chain of code.
       const response =axios.get("http://localhost:4100/venue/all?size=6&page=0")
       .then((response) => {
        // Handle successful response
        console.log("Debug :: Venues ",response.data.content);
        setVenues(response.data.content);

      }).catch((error)=>{
        console.log("Error :: During the GetVenues Call ",error)
       })
       console.log("All Venues ",response);
       
    },[]); 
    
        console.log("VenuesX ",venues);
        return (
          <VenueListContainer>
            {venues.map((venue) => (
              <VenueCard key={venue.id} venue={venue} />
            ))}
           </VenueListContainer>
        );

    }

export default VenueList;